<template>
  <div class="container-fluid h-100 d-flex flex-column scrollable-table global-padding">
    <div class="text-center">
      <h2 data-jp="dealer-addresses-headline">
        {{ $t("UPDATE_DEALER_ADDRESSES") }}
      </h2>
      <p class="mb-1">
        {{ $t("UPDATE_DEALER_ADDRESSES_TEXT") }}
      </p>
      <p>
        {{ $t("UPDATE_DEALER_ADDRESSES_TEXT2") }}
      </p>
    </div>
    <div class="dealer-addresses-controls pb-2">
      <div class="text-left flex">
        <div class="d-inline-flex align-items-center">
          <input
            class="form-check-input checkbox mt-0 me-3"
            type="checkbox"
            id="cb1"
            v-model="dealerAddressSetup.noChange"
            @change="updateDealerAddressSetup"
          />
          <label for="cb1" style="width: auto;">{{ $t("UPDATE_DEALER_ADDRESSES_CHECKBOX1") }}</label>
        </div>
        <div class="d-inline-flex align-items-center">
          <input
            class="form-check-input checkbox mt-0 me-3"
            type="checkbox"
            id="cb2"
            v-model="dealerAddressSetup.sendToPag"
            @change="updateDealerAddressSetup"
          />
          <label for="cb2" style="width: auto;">{{ $t("UPDATE_DEALER_ADDRESSES_CHECKBOX2") }}</label>
        </div>
        <div class="d-inline-flex align-items-center">
          <input
            class="form-check-input checkbox mt-0 me-3"
            type="checkbox"
            id="cb3"
            v-model="dealerAddressSetup.importedByBold"
            @change="updateDealerAddressSetup"
          />
          <label for="cb3" style="width: auto;">{{ $t("UPDATE_DEALER_ADDRESSES_CHECKBOX3") }}</label>
        </div>
      </div>
      <div class="m-auto">
        <b>{{ $t("NUMBER_OF_DEALERS") }}</b>
        {{ dealers.length }}
      </div>
      <button          
        type="button"
        class="align-self-start btn btn-outline-dark border-radius-0 m-auto"
        @click="getLocationReport()"
      >
        <i class="bi bi-file-earmark-excel h5 me-2"> </i>
        {{ $t("DOWNLOAD_DEALER_ADDRESSES") }}
      </button>
      <div class="d-flex justify-content-between">
        <button
          type="button"
          class="align-self-start btn btn-outline-dark border-radius-0 my-auto mx-1"
          @click="showFileModal()"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          :title="$t('STAFF_NAMES_INFO_TEXT')"
        >
          <i class="bi bi-file-earmark-medical-fill h5 me-2"> </i>
          {{ $t("UPLOAD_OVERALL") }}
        </button>
        <!--start contact possibility-->
        <button          
          type="button"
          class="align-self-start btn btn-outline-dark border-radius-0 m-auto"
          @click="getGDPRFile()"
        >
          <i class="bi bi-file-earmark-medical-fill h5 me-2"> </i>
          {{ $t("DOWNLOAD_GDPR_DOCUMENT") }}
        </button>
      </div>
      
    </div>
    <Transition name="bounce">
      <Suspense v-if="showModal">
        <LocationFileManagement
          :locationID="selectedLocationID"
          :toggleModal="toggleModal"
        ></LocationFileManagement>
      </Suspense>
    </Transition>

    <div class="table-responsive flex-full-height mb-1" style="border-bottom: 1px solid rgb(222, 226, 230);">
      <table class="table dealers-table" id="table">
        <thead class="table-light" style=" vertical-align: middle;">
          <tr>
            <th
              scope="col"
              :class="col.sort_state"
              @click="sort(col)"
              v-for="col in columns"
              :key="col.title"
              class="border-0 sticky-top py-0"
              :style="col.style"
            >
              {{ $t((col.title === "name" ? "DEALER_" : "") + col.title.toUpperCase()) }}
              <i class="bi bi-arrow-up text-muted"></i>
              <i class="bi bi-arrow-down text-muted"></i>
            </th>
          </tr>
          <tr>
            <th
              scope="col"
              :class="col.sort_state"
              v-for="col in columns"
              :key="'filter_' + col.title"
              class="pt-0"
              :style="col.style"
            >
              <div class="input-group">
                <input
                  :disabled="activeWaveReadonly"
                  type="text"
                  class="form-control border-0 bg-none ps-0 pb-0"
                  placeholder="Filter..."
                  aria-label="Filter"
                  v-if="
                    col.title !== 'actions' &&
                    col.title !== 'staffNameOnReport' &&
                    col.title !== 'upload_documents'
                  "
                  v-model="col.filter"
                  @input="filterRows"
                  :data-jp="col.title === 'city' ? 'filter-input-city' : ''"
                />
              </div>

              <div class="row" v-if="col.title === 'staffNameOnReport'">
                <div class="col-auto  tbody__cell--files">
                  <input
                    :disabled="activeWaveReadonly"
                    class="form-check-input checkbox mt-0"
                    type="checkbox"
                    v-model="all_dealer_selected"
                    :indeterminate.prop="all_dealer_select_indeterminate"
                    aria-label="Checkbox for following text input"
                    id="select_all_dealer"
                    @click.prevent="confirmAllcheck"
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody style=" vertical-align: middle; height: calc(100% - 87px);">
          <tr v-for="dealer in filtered_dealers" :key="dealer.id">
            <td 
              v-for="col in columns"
              :key="col.title"
              :style="col.style"
            >
              <div class="tbody__cell--files" v-if="col.title == 'staffNameOnReport'">
                <input
                  class="form-check-input checkbox"
                  type="checkbox"
                  v-model="dealer.staffNameOnReport"
                  aria-label="Checkbox for following text input"
                  @input="changeAllSelectState($event, dealer)"
                  :disabled="activeWaveReadonly"
                />
                <!-- not render block due to new data protection requirements -->
                <div v-if="false">
                  <div
                    v-if="dealer.staffNameOnReport"
                    :class="dealer.staffNameOnReport ? 'cursor-pointer text-reset' : 'disabled text-muted'"
                    @click="showFileModal(dealer.id)"
                  >
                    <i :class="dealer.file_link ? 'bi bi-file-earmark-medical-fill h5' : 'bi bi-upload h5'"></i>
                    manage files
                  </div>
                  <div
                    v-else
                    :class="dealer.staffNameOnReport ? 'cursor-pointer text-reset' : 'disabled text-muted'"
                  >
                    <i :class="dealer.file_link ? 'bi bi-file-earmark-medical-fill h5' : 'bi bi-upload h5'"></i>
                    manage files
                  </div>
                </div>
              </div>
              <div v-else>
                {{ dealer[col.title] }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row no-gutters mb-3">
      <div class="col-6 d-flex justify-content-start">
        <button
          class="btn btn-secondary"
          @click="$router.push(`/projects/${$route.params.projectGuid}/waves/${$route.params.waveGuid}/general-setup`)"
        >
          {{ $t("PREVIOUS") }}
        </button>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button
          class="btn btn-primary"
          @click="$router.push(`/projects/${$route.params.projectGuid}/waves/${$route.params.waveGuid}/appeal-management`)"
        >
          {{ $t("NEXT") }}
        </button>
      </div>
    </div>

    <confirm-submit-modal
      :title="$t('NOT_UNDOABLE_ACTION')"
      :text="$t('SELECT_ALL_STAFF_NAMES')"
      :button_text="$t('YES')"
      :modal_id="'myModal'"
      @confirm="toggleAllDealer"
    />
  </div>
</template>

<script>
import { ref } from "vue";

import LocationFileManagement from "../components/DealerAdresse/LocationFileManagement.vue";
import ConfirmSubmitModal from "@/components/modals/ConfirmSubmitModal.vue";
import { Modal } from "bootstrap";
import { useStore } from "vuex";

export default {
  components: {
    ConfirmSubmitModal,
    LocationFileManagement,
  },
  name: "DealerAddresses",
  async setup() {
    const store = useStore();
    await store.dispatch("dealers/loadDealers");

    const showModal = ref(false);
    const toggleModal = () => {
      showModal.value = !showModal.value;
    };

    let dealerAddressSetup = store.getters["waves/getDealerAddressSetup"];

    return {
      showModal,
      toggleModal,
      selectedLocationID: -1,
      selectAllModal: {},
      dealerConfirmUploadFileDealerModal: {},
      fileConfirmUploadFileDealerModal: {},
      filtered_dealers: [],
      columns: [
        {
          title: "staffNameOnReport",
          sort_state: "",
          filter: "",
          style: "width: 110px; text-align: center"
        },
        {
          title: "locationId_projects",
          sort_state: "",
          filter: "",
          style: "width: 75px"
        },
        {
          title: "partnerId",
          sort_state: "",
          filter: "",
        },
        {
          title: "name",
          sort_state: "",
          filter: "",
          style: "width: 200px"
        },
        {
          title: "street",
          sort_state: "",
          filter: "",
          style: "width: 225px"
        },
        {
          title: "city",
          sort_state: "",
          filter: "",
          style: "width: 110px"
        },
        {
          title: "postalCode",
          sort_state: "",
          filter: "",
          style: "width: 110px"
        },
        {
          title: "country",
          sort_state: "",
          filter: "",
          style: "width: 80px"
        },
        {
          title: "locationExportDefaultLanguageCode",
          sort_state: "",
          filter: "",
          style: "width: 90px"
        },
        {
          title: "area",
          sort_state: "",
          filter: "",
          style: "width: 80px"
        },
        {
          title: "salesRegion",
          sort_state: "",
          filter: "",
        },
        {
          title: "dealerGroup",
          sort_state: "",
          filter: "",
        },
        {
          title: "buildingType",
          sort_state: "",
          filter: "",
        },
      ],
      all_dealer_select_indeterminate: false,
      selectedFile: null,
      all_dealer_selected: false,
      dealerAddressSetup
    };
  },
  methods: {
    showFileModal(id) {
      this.selectedLocationID = id;
      this.showModal = true;
    },
    changeAllSelectState($event, dealer) {
      this.hideDropdown();

      this.filtered_dealers.forEach((d) => {
        if (d.id === dealer.id) {
          d.staffNameOnReport = $event.target.checked;
        }
      });

      this.checkCheckboxIndeterminateState();
      this.$store.dispatch("dealers/editDealer", dealer);
    },
    checkCheckboxIndeterminateState() {
      let selected = this.filtered_dealers.filter(
        (dealer) => dealer.staffNameOnReport
      );
      // when all dealer checkbox checked and this checkbox is unchecked
      if (
        selected.length !== 0 &&
        selected.length < this.filtered_dealers.length
      ) {
        this.all_dealer_selected = false;
        this.all_dealer_select_indeterminate = true;
      }
      if (selected.length === 0) {
        this.all_dealer_selected = false;
        this.all_dealer_select_indeterminate = false;
      }
      if (selected.length === this.filtered_dealers.length) {
        this.all_dealer_select_indeterminate = false;
        this.all_dealer_selected = true;
      }
    },
    toggleAllDealer() {
      this.all_dealer_selected = !this.all_dealer_selected;
      this.$store.dispatch("dealers/checkAllDealers", this.all_dealer_selected);
      if (this.all_dealer_selected) {
        this.showFileModal();
      }
    },
    confirmAllcheck() {
      this.hideDropdown();
      this.selectAllModal.show();
    },
    sort(col, whith_change) {
      if (
        col.title === "actions" ||
        col.title === "staffNameOnReport" ||
        col.title === "upload_documents"
      ) {
        return false;
      }
      this.columns.forEach((c) => {
        if (c.title === col.title) return;
        c.sort_state = "";
      });

      if (col.sort_state === "desc" || col.sort_state === "") {
        this.filtered_dealers.sort((a, b) =>
          a[col.title] > b[col.title] ? 1 : b[col.title] > a[col.title] ? -1 : 0
        );
        col.sort_state = "asc";
      } else {
        this.filtered_dealers.sort((a, b) =>
          a[col.title] < b[col.title] ? 1 : b[col.title] < a[col.title] ? -1 : 0
        );
        col.sort_state = "desc";
      }
      if (whith_change) {
        col.sort_state = col.sort_state === "asc" ? "desc" : "asc";
      }
    },
    filterRows() {
      this.filtered_dealers = [...this.dealers];
      let filtered_columns = 0;
      this.columns.forEach((c) => {
        if (c.filter !== "") {
          this.filtered_dealers = this.filtered_dealers.filter((dealer) => {
            return dealer[c.title]
              ? String(dealer[c.title])
                  .trim()
                  .toLowerCase()
                  .includes(c.filter.trim().toLowerCase())
              : false;
          });
        }

        // sort again after filter
        if (c.sort_state !== "") {
          this.sort(c, false);
        }

        if (c.filter !== "") {
          filtered_columns++;
        }
      });
      if (filtered_columns === 0) {
        this.filtered_dealers = [...this.dealers];
      }
      this.$forceUpdate();
    },
    hideDropdown() {
      document
        .querySelectorAll("a[data-bs-toggle='dropdown']")
        .forEach((el) => {
          el.classList.remove("show");
          el.setAttribute("aria-expanded", "false");
        });

      document
        .querySelectorAll(".dropdown-menu")
        .forEach((el) => el.classList.remove("show"));
    },
    async updateDealerAddressSetup() {
      await this.$store.dispatch("waves/updateDealerAddressSetup", this.dealerAddressSetup);
      this.dealerAddressSetup = this.$store.getters["waves/getDealerAddressSetup"];
      this.$forceUpdate();
    },
    async getLocationReport() {
      this.$store.dispatch("dealers/getLocationReport");
    },
    async getGDPRFile() {
      await this.$store.dispatch("downloads/downloadFile", "gdpr");
    }
  },
  computed: {
    dealers() {
      return this.$store.getters["dealers/getAllDealers"];
    },
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  beforeMount() {
    this.filtered_dealers = [...this.dealers];
    this.sort(this.columns[0], true);
    this.checkCheckboxIndeterminateState();
  },
  watch: {
    dealers(dealers) {
      this.filterRows();
      this.checkCheckboxIndeterminateState();
    },
  },
  mounted() {
    const myModalEl = document.querySelector("#myModal");
    const modal = new Modal(myModalEl);
    this.selectAllModal = modal;
  },
};
</script>

<style lang="scss" scoped>
table {
  th {
    .bi-arrow-up {
      cursor: pointer;
      display: none;
    }

    .bi-arrow-down {
      cursor: pointer;
      display: none;
    }

    &.asc {
      .bi-arrow-up {
        display: inline;
      }

      .bi-arrow-down {
        display: none;
      }
    }

    &.desc {
      .bi-arrow-up {
        display: none;
      }

      .bi-arrow-down {
        display: inline;
      }
    }
  }
  tbody {
    tr {
      width: calc(100% - 0.28em);
    }
    &::-webkit-scrollbar {
      width: 0.5em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 50px;
      outline: 1px solid slategrey;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  }
}
label {
  user-select: none;
}
.tbody__cell--files {
  display: flex;
  justify-content: center;
  width: 100%;
}

.form-control:focus {
  border-color: transparent;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
}
.dealer-addresses-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
