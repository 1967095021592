<template>
  <dialog ref="dialogRef">
    <div class="flex">
      <div class="modal-header">
        <h5>Manage Files</h5>
        <button @click="hideModal" class="btn-close"></button>
      </div>
      <div v-if="error" style="min-width: 400px">
        {{ error }}
        <div class="row">
          <div class="col-4"></div>
          <div class="col-4"></div>
          <div class="col-4">
            <button @click="hideError" class="btn btn-secondary">Ok</button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="modal-body">
          <div class="files" v-for="file in files" :key="file.id">
            {{ file.name }}
            <p />
            <div
              class="btn btn-secondary"
              @click="downloadFile(file.id, file.name)"
            >
              Download
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <h6>Upload Files</h6>
          <button
            @click="confirmUpload"
            class="btn btn-secondary"
            :disabled="activeWaveReadonly"
          >
            Confirm
          </button>
          <button
            @click="clear"
            class="btn btn-primary"
            :disabled="activeWaveReadonly"
          >
            Abort
          </button>
          <input
            multiple
            ref="inputRef"
            class="form-control"
            @change="changeFilesToUpload"
            :disabled="activeWaveReadonly"
            type="file"
            accept=".pdf,.doc,.docx,.xlsx,.xls,.pptx,.ppt,.odt,.ods,.odg,.png,.jpeg,.jpg,.raw,.tiff"
          />
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { onBeforeUnmount, onErrorCaptured, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import * as snackbar from "snackbar";
import axios from "axios";

snackbar.duration = 2000;

/**
 * A File Dialog that fetches and displays the file for a given location.
 * Allows the user to Upload multiple files to that Location
 * @displayName LocationFileManagement
 */
export default {
  name: "LocationFileManagement",
  props: ["toggleModal", "locationID"],
  async setup(props) {
    const dialogRef = ref(null);
    const inputRef = ref(null);
    const store = useStore();
    const waveguid = store.getters["waves/getActiveWaveGuid"];
    const uploadTarget = ref([]);

    onMounted(() => {
      if (!dialogRef.value) throw new Error("missing ref");
      dialogRef.value.showModal();
    });
    onBeforeUnmount(() => dialogRef.value.close());
    onErrorCaptured((err) => {
      store.commit("dealers/setError", err);
      return false;
    });

    const urls = (locID) => {
      if (locID) {
        return {
          downloadURL: `/${process.env.VUE_APP_API_URI}/waves/${waveguid}/location/${locID}/files`,
          uploadURL: `/${process.env.VUE_APP_API_URI}/waves/${waveguid}/locations/${props.locationID}/file`,
        };
      } else {
        return {
          downloadURL: `/${process.env.VUE_APP_API_URI}/waves/${waveguid}/locations/files`,
          uploadURL: `/${process.env.VUE_APP_API_URI}/waves/${waveguid}/locations/file`,
        };
      }
    };

    const { downloadURL, uploadURL } = urls(props.locationID);
    const res = await axios.get(downloadURL, {
      headers: {
        CallSpinner: true,
      },
    });
    const files = reactive(res.data.data);

    const changeFilesToUpload = (e) => {
      uploadTarget.value = e.target.files;
    };

    const clear = () => (inputRef.value.value = null);

    const uploadFile = async (file) => {
      const form_data = new FormData();
      form_data.append("formFile", file);
      return await axios.post(uploadURL, form_data, {
        headers: {
          CallSpinner: true,
        },
      });
    };

    const confirmUpload = async () => {
      const upload = [...uploadTarget.value];
      if (!upload || upload.length < 1) {
        snackbar.show("Nothing to Upload");
        store.commit("dealers/setError", "Nothing to Upload");
        return;
      }
      snackbar.show("Upload started");
      try {
        const ret = await Promise.all(upload.map((f) => uploadFile(f)));
      } catch (error) {
        snackbar.show("Upload Failed:" + error);
        store.commit("dealers/setError", "Upload Failed:" + error);
        clear();
        return;
      }
      inputRef.value.value = null;
      snackbar.show("Upload complete");
      const res = await axios.get(downloadURL, {
        headers: {
          CallSpinner: true,
        },
      });
      Object.assign(files, res.data.data);
    };

    const downloadFile = async (fileId, filename) => {
      const res = await axios.get(`/api/waves/file/${fileId}`, {
        responseType: "blob",
        headers: {
          CallSpinner: true,
        },
      });
      if (!res.data) {
        store.commit("dealers/setError", "No data recieved on download");
      }

      const FILE = window.URL.createObjectURL(new Blob([res.data]));
      const docUrl = document.createElement("a");
      docUrl.href = FILE;
      docUrl.setAttribute("download", filename);
      document.body.appendChild(docUrl);
      docUrl.click();
      docUrl.remove();
    };

    const datefmt = (datestring) => {
      const d = new Date(datestring);
      return d.toLocaleString();
    };

    return {
      dialogRef,
      inputRef,
      files,
      clear,
      confirmUpload,
      downloadFile,
      datefmt,
      changeFilesToUpload,
    };
  },
  computed: {
    error() {
      return this.$store.getters["dealers/getError"];
    },
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  methods: {
    hideError() {
      this.$store.commit("dealers/setError", undefined);
    },
    hideModal() {
      this.$store.commit("dealers/setError", undefined);
      this.toggleModal();
    },
  },
};
</script>

<style lang="scss">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: translate(0, -50px);
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.9;
  }
  100% {
    transform: none;
  }
}

.files {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5em;
  padding-block-start: 0.3em;
  padding-block-end: 0.3em;
}

.flex {
  display: flex;
  flex-direction: column;
}

dialog {
  /* Copied from boostrap without the bs-dismis */
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}
</style>
